.hero-title {
    -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-23 9:42:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-pop-up-top
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}

@keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}