@import url("https://fonts.googleapis.com/css2?family=Onest:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --color-dark: #161a1f;
  --color-dark-x: #42474c;
  --color-primary: #fdb819;
  --color-primary-x: #e9c36c;
  --color-primary-xx: #ecac17;
}

body {
  padding: 0;
  margin: 0;
}

a {
  color: unset;
  text-decoration: none;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.colored-dark-x {
  color: var(--color-dark-x);
}

.logo p {
  font-size: 2.2em;
  font-weight: 900;
  color: var(--color-primary);
  line-height: 1;
  margin: 0;
  padding: 0;
}

.logo p span {
  color: var(--color-dark);
  padding: 0;
  margin: 0;
}

.Contacts-heading {
  font-size: 15px;
  color: rgb(12, 12, 12);
}

.contacts-text {
  padding: 25px 0;
}

.contacts-info {
  display: flex;
  flex-direction: row;
}

.contacts-icon p a {
  padding-left: 10px;
}

/* marquee */
@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-30%);
  }
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  padding: 10px 0;
}

.marquee {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2em;
  white-space: nowrap;
  animation: marquee 15s linear infinite;
}

.marquee-container:hover .marquee {
  animation-play-state: paused;
}

.marquee img {
  display: inline-block;
  height: 40px;
  /* filter: grayscale(100%);
  transition: filter .75s ease-in-out; */
}

/* .marquee img:hover {
  cursor: pointer;
  filter: saturate(100%);
} */

.slide-in-left {
  -webkit-animation: slide-in-left .5s cubic-bezier(.25, .46, .45, .94) both;
  animation: slide-in-left .5s cubic-bezier(.25, .46, .45, .94) both
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-24 23:46:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1
  }
}

.slide-in-right {
  -webkit-animation: slide-in-right .5s cubic-bezier(.25, .46, .45, .94) both;
  animation: slide-in-right .5s cubic-bezier(.25, .46, .45, .94) both
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-27 10:1:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1
  }
}

.service-wrapper {
  cursor: pointer;
}

.service-wrapper:hover .service-image {
  scale: 1.3;
  z-index: -2;
  transition: all .75s ease-in-out;
}

.sticky-app-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  -webkit-animation: slide-in-right .5s cubic-bezier(.25, .46, .45, .94) both;
  animation: slide-in-right .5s cubic-bezier(.25, .46, .45, .94) both
}